import { SplitFactory } from "@splitsoftware/splitio";

const splitServerConfig = {
  core: {
    authorizationKey: process.env.NEXT_PUBLIC_SPLIT_SERVER_KEY,
    trafficType: "anonymous",
  },
};
// @ts-expect-error: Wrong type
export const splitServerClient = SplitFactory(splitServerConfig).client();
