import type { BuilderComponent } from "@builder.io/react";

import { analytics } from "@flare/analytics";

import { BiEventsNames } from "@/services/analytics/event-names";

type Content = BuilderComponent["content"];

export const trackExperimentEvent = (content: Content) => {
  const builderPageName = content?.name;
  const testVariationId = content?.testVariationId;
  const testVariationName = content?.testVariationName;

  if (Boolean(testVariationId) && Boolean(testVariationName)) {
    analytics.track(BiEventsNames.WebExperimentTreatment, {
      builder_page_name: builderPageName,
      test_variation_id: testVariationId,
      test_variation_name: testVariationName,
    });
  }
};
