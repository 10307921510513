/**
 * Next.js server-side rendering cache time - when the cache expires, pages are
 * rebuilt on being requested (and possible changes or new articles are visible)
 */
export const REVALIDATION_TIME_SECONDS =
  parseInt(process.env.NEXT_PUBLIC_REVALIDATION_TIME_MINUTES ?? "10") * 60;

/**
 * Builder getAll pages limit using model name, default = 30
 */
export const BUILDER_PAGES_LIMIT = 1000;
