/**
 * @file model-names.ts
 *
 * @desc This file contains the names of the models in the builder.
 */

export const pagesModel = {
  page: "page",
  localSEO: "local-seo-page",
  acquisition: "acquisition-page",
  websitePractice: "website-practice-page",
  websiteService: "website-service-page",
  local: "local-page",
  answer: "answer-page",
} as const;

export const dataModel = {
  landingPage: "landing-page-data",
  localSEO: "local-seo-data",
  acquisitionPractice: "acquisition-practice-data",
  acquisitionState: "acquisition-state-data",
  websitePractice: "website-practice-data",
  websiteService: "website-service-data",
  managingAttorney: "managing-attorney-data",
} as const;

export const sectionModel = {
  symbol: "symbol",
} as const;
