export const featureFlagNames = {
  AA_TEST: "AA_TEST",
  CLICK_TO_CALL: "CLICK_TO_CALL",
  AA_TEST_FAMILY_GENERAL: "AA_TEST_FAMILY_GENERAL",
  AA_TEST_FAMILY_SUB_PRACTICES: "AA_TEST_FAMILY_SUB_PRACTICES",
  FUNNEL_TEST_DIRECT_LSS_LOW: "FUNNEL_TEST_DIRECT_LSS_LOW",
  FUNNEL_TEST_DIRECT_LSS_MID: "FUNNEL_TEST_DIRECT_LSS_MID",
  FUNNEL_TEST_DIRECT_LSS_IMM: "FUNNEL_TEST_DIRECT_LSS_IMM",
  FUNNEL_TEST_DIRECT_LSS_HIGH: "FUNNEL_TEST_DIRECT_LSS_HIGH",
  FUNNEL_TEST_CMN_MID: "FUNNEL_TEST_CMN_MID",
  FUNNEL_TEST_MARBLE_MEET_CONTENT: "FUNNEL_TEST_MARBLE_MEET_CONTENT",
  FUNNEL_TEST_IMMIGRATION_ELIGIBILITY: "FUNNEL_TEST_IMMIGRATION_ELIGIBILITY",
  DIALOG_FLOW_CHAT_BOT: "DIALOG_FLOW_CHAT_BOT",
  FUNNEL_TEST_ATTORNEY_AVAILABILITY: "FUNNEL_TEST_ATTORNEY_AVAILABILITY",
} as const;

export type FeatureFlagName = keyof typeof featureFlagNames;
