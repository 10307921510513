import Script from "next/script";

import { useFeatureFlag } from "@/services/experiments";
import { featureFlagNames } from "@/services/experiments/constants";

export function DialogFlowChatbot() {
  let pathname = "";
  if (typeof window !== "undefined") {
    pathname = window.location.pathname;
  }

  const { isOn, isReady } = useFeatureFlag(
    featureFlagNames.DIALOG_FLOW_CHAT_BOT,
    {
      pathname,
    },
  );
  return (
    isReady &&
    isOn && (
      <>
        <Script src="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js" />
        {/* @ts-expect-error - df-messenger isn't a valid HTML element */}
        <df-messenger
          project-id="flare-353110"
          agent-id="087e12d3-c8d6-4048-aba2-143c552afbbe"
          language-code="en"
          intent="StartEvent"
          max-query-length="-1"
          class="fixed bottom-4 right-4 z-50"
        >
          {/* @ts-expect-error - df-messenger-chat-bubble isn't a valid HTML element */}
          <df-messenger-chat-bubble
            chat-title="Marble"
            chat-icon="https://cdn.builder.io/api/v1/image/assets%2F15f74e88630d41b6b43ece1273087b6f%2F8f784897e2124ccab448f39350f6fdce"
          >
            {/* @ts-expect-error - df-messenger-chat-bubble isn't a valid HTML element */}
          </df-messenger-chat-bubble>
          {/* @ts-expect-error - df-messenger isn't a valid HTML element */}
        </df-messenger>
      </>
    )
  );
}
