import type { FeatureFlagName } from "../constants";
import { getTreatmentConfig } from "../utils";

import { splitServerClient } from "./split";

export async function getServerFeatureFlag(
  name: FeatureFlagName,
  attributes: SplitIO.Attributes = {},
) {
  await splitServerClient.ready();
  const result = splitServerClient.getTreatmentWithConfig(
    "anonymousKey",
    name,
    attributes,
  );

  // @ts-expect-error: Wrong type
  const isOn = result.treatment === "on";
  // @ts-expect-error: Wrong type
  const config = getTreatmentConfig(result.config);

  return { isOn, config };
}
