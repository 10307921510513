/**
 * Converts a raw Split.io treatment configuration to a parsed treatment configuration object.
 *
 * @param {string} splitConfig - A JSON string containing the split configuration data.
 * @returns {Record<string, unknown>} - The parsed treatment configuration as a JavaScript object. An empty object is returned if the input is empty or not a valid JSON string.
 */
export function getTreatmentConfig(splitConfig?: string | null | undefined) {
  let config: Record<string, unknown> = {};

  try {
    if (splitConfig) {
      config = JSON.parse(splitConfig);
    }
  } catch (e) {
    console.error(`Error decoding split config`, e);
  }

  return config;
}
